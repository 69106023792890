// Page Load Event
$(function() {
    setupMobileMenu();
    setupTouchBodyMenuHide();

    if (!(window.ActiveXObject) && "ActiveXObject" in window) {
        $("body").addClass("iefont");
    }
    if (navigator.userAgent.match(/msie/i)) {
        $("html").addClass("ie");
    }
    
    // Lazy load images, bg images, and video
    var allimages = document.getElementsByClassName('b-lazy');
    for (var i = 0; i < allimages.length; i++) {
        if (allimages[i].getAttribute('data-src')) {
            allimages[i].setAttribute('src', allimages[i].getAttribute('data-src'));
        }
    }
    //-------------------------------------------------------
    // Mobile Menu

    function setupMobileMenu() {
        var $hamburger = $(".hamburger");
        var $hamburgerTarget = $('#usda-mobile-menu');
        var $tint = $('.bg-tint');
        var $header = $('#usda-header');
        var $next = $('.m-nav-sub-link');
        var $prev = $('.m-nav-prev-link');
        var $body = $('#pageTop');

        var _closeAllMenus = function () {
            $hamburgerTarget.removeClass("is-active");
            $hamburger.removeClass("is-active");
            $tint.removeClass("is-active");
            $header.removeClass("is-active");
            $body.removeClass("mobile-menu-open");
        };

        if ($hamburger && $hamburgerTarget) {
            var $hamburgerToggleEls = $hamburger;
            $hamburgerToggleEls.on("click", function (e) {
                $hamburger.toggleClass("is-active");
                $hamburgerTarget.toggleClass("is-active");
                $tint.toggleClass("is-active");
                $header.toggleClass("is-active");
                $body.toggleClass("mobile-menu-open");
                e.preventDefault();
            });
        }

        $next.on('click', function (e) {
            var link = $(e.target);
            var subMenu = link.siblings(".m-nav-sub-panel");
            var subLink = subMenu.find(".m-nav-prev-link");
            link.attr("aria-expanded", true);

            var animationEnded = function () {
                subMenu.off("webkitAnimationEnd animationend msAnimationEnd oAnimationEnd", animationEnded);
                subLink.trigger('focus');
            };
            subMenu.on("webkitAnimationEnd animationend msAnimationEnd oAnimationEnd", animationEnded);

            subMenu.addClass("is-active");
            subMenu.addClass("slideInRight");
            subMenu.removeClass("slideOutRight");
        });

        $prev.on('click', function (e) {
            var subLink = $(e.target);
            var subMenu = subLink.closest(".m-nav-sub-panel");
            var link = subMenu.siblings(".m-nav-sub-link");
            link.attr("aria-expanded", false);
            subMenu.addClass("slideOutRight");
            subMenu.removeClass("slideInRight");
            link.trigger('focus');
        });

        $tint.on('click', function () {
            _closeAllMenus();
        });

        $(".menu-close").on('click', function () {
            _closeAllMenus();
        });
    }

    //-------------------------------------------------------
    // Top Navigation Arrow event handlers

    // Opens/closes the submenu when the arrow is clicked, tapped, or the enter key is pressed
    function topMenuArrowAction($this, $topmenu) {
        if ($topmenu.is('.open')) {
            // if open, close it
            closeMenuTouch($topmenu, 'fa-angle-down', 'fa-angle-up');
            $this.attr("aria-expanded", false);
        } else {

            // close other tertiary menus on this fat nav 
            $topmenu.siblings().each(function(index, current) {
                var $current = $(current);
                closeMenuTouch($current, 'fa-angle-down', 'fa-angle-up');
            });

            // open it
            openMenuTouch($topmenu, 'fa-angle-down', 'fa-angle-up');
            $this.attr("aria-expanded", true);

        }
    }

    // Click handler for main top navigation arrows
    function onTopArrowClick(e) {
        var $this = $(this);
        var $topmenu = $this.parent();

        topMenuArrowAction($this, $topmenu);

        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    // Keydown handler for main top navigation arrows
    function onTopArrowKeydown(e) {
        if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();

            var $this = $(this);
            var $topmenu = $this.parent();

            topMenuArrowAction($this, $topmenu);

            return false;
        }
    }

    //-------------------------------------------------------

    //-------------------------------------------------------
    // Sub Navigation Arrow event handlers

    // Opens/closes the submenu when the arrow is clicked, tapped, or the enter key is pressed
    function subMenuArrowAction($this, $submenu) {
        if ($submenu.is('.open')) {

            // if open, close it
            closeMenuTouch($submenu, 'fa-angle-right', 'fa-angle-left');

        } else {

            // close other tertiary menus on this fat nav 
            $submenu.siblings().each(function(index, current) {
                var $current = $(current);
                closeMenuTouch($current, 'fa-angle-right', 'fa-angle-left');
            });

            // open current tertiary menu
            openMenuTouch($submenu, 'fa-angle-right', 'fa-angle-left');
        }
    }

    // Click handler for sub navigation arrows
    function onSubArrowClick(e) {
        var $this = $(this);
        var $submenu = $this.parent();

        subMenuArrowAction($this, $submenu);

        e.preventDefault();
        e.stopPropagation();
        return false;
    }


    // Keydown handler for sub navigation arrows
    function onSubArrowKeydown(e) {
        if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();

            var $this = $(this);
            var $submenu = $this.parent();

            subMenuArrowAction($this, $submenu);

            return false;
        }
    }


    //-------------------------------------------------------

    // Wire-up events for arrow handlers
    $(".dropdown a.top-indicator").on("click", onTopArrowClick);
    $(".dropdown a.top-indicator").on("keydown", onTopArrowKeydown);

    $(".dropdown-submenu a.tertiary-toggle").on("click", onSubArrowClick);
    $(".dropdown-submenu a.tertiary-toggle").on("keydown", onSubArrowKeydown);

    $(".emptylink").on("click", function(e) {
        e.preventDefault();
        return false;
    });

    $(window).on('scroll', function() {
        var sticky = $('.usdaNav'),
            scroll = $(window).scrollTop(),
            $body = $("body");

        if (scroll >= 100) {
            sticky.addClass('fixed');
            $body.addClass('navStickyOffset');
        } else {
            sticky.removeClass('fixed');
            $body.removeClass('navStickyOffset');
        }
    });

    var $searchWrapper = $('#headerSearch');
    $(".mag-submit").on('click', function() {
        var $searchBox = $searchWrapper.find(".search-block-header"); // input with the search text

        if ($searchWrapper.hasClass('search-expanded')) {
            if ($searchBox.val() == null || $searchBox.val() == undefined || $searchBox.val() === '') {
                //this probably isn't nessesary, but we want to clear the box just in case.
                $searchBox.val('');
                $searchWrapper.removeClass("search-expanded");
            } else {
                $('#headerSearch form').submit();
            }

        } else {
            $searchWrapper.addClass("search-expanded");
            setTimeout(function () { $('.search-block-header').trigger('focus') }, 1000);
        }
    });

    $('.search-block-header').on('keypress', function(e) {
        if (e.which === 13) {
            $('form#headerSearch').submit();
        }
    });

    //bindJumpLinks();

    //SMOOTH SCROLL
    $('a.backToTop[href*="#"]:not([href="#"])').on('click', function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });

    //BACK TO TOP
    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 1040 && !$('a.backToTop').hasClass('btt-in')) {
            $('a.backToTop').addClass('btt-in');
        } else if ($(this).scrollTop() <= 1040 && $('a.backToTop').hasClass('btt-in')) {
            $('a.backToTop').removeClass('btt-in');
        }
    });

    // Expand all button 
    $('.ers-accordion-expand.custom-expand').click(function () {
        $('.usa-accordion__button.custom-expand__button').attr('aria-expanded', 'true');
        $('.usa-accordion__content.custom-expand__content').attr('hidden', false);
    });

    // Collapse all button
    $('.ers-accordion-collapse.custom-collapse').click(function () {
        $('.usa-accordion__button.custom-collapse__button').attr('aria-expanded', 'false');
        $('.usa-accordion__content.custom-collapse__content').attr('hidden', true);
    });


});

//This is the expanded jumpLinkWithoutHash function from dataproductsfilter.js
function bindJumpLinks() {
    var links = $('a').filter(function(index, element) {
        var href = $(element).attr("href");
        if (href == "" || href == null) {
            return false;
        }
        return href[0] == "#" && !href.includes("mm"); //only get a tags that start with a # and dont have mm in the link
    }).filter(function(index, element) {
        var parents = $(element).parents(".mobile-menu-flyout");
        return parents.length == 0; //Only select elements NOT in mobile-menu-flyout
    }).filter(function(index, element) {
        return !$(element).hasClass("hamburger"); //Throw away any element with the hamburger class... we don't want to mess with that
    }).on('click', function(e) {
        e.preventDefault();
        var hash = $(this).attr("href");
        var $target = $(hash);
        var $nav = $('#usdaNav');
        $('html, body').animate({
            scrollTop: $target.offset().top - $nav.height() //the height of the nav bar
        }, 500, function() {
            document.getElementById(hash.substring(1)).focus({ preventScroll: true });
        });
        return false;
    });
}


function openMenuTouch($this, iconClosedArrowStyle, iconOpenArrowStyle) {
    $this.children('.dropdown-menu').stop(true, true).fadeIn("fast");
    $this.addClass('open');
    $this.children('a').find('.fa').removeClass(iconClosedArrowStyle).addClass(iconOpenArrowStyle);
    $this.children('a.tertiary-toggle').attr("aria-expanded", true);
}

function closeMenuTouch($this, iconClosedArrowStyle, iconOpenArrowStyle) {
    $this.children('.dropdown-menu').stop(true, true).fadeOut("fast");
    $this.removeClass('open');
    $this.children('a').find('.fa').removeClass(iconOpenArrowStyle).addClass(iconClosedArrowStyle);
    $this.children('a.tertiary-toggle').attr("aria-expanded", false);
}

// All users to click anywhere outside the fat nav to get rid of it
function setupTouchBodyMenuHide() {
    $(window).on("click", function(e) {
        $('.dropdown').siblings().each(function(index, current) {
            var $this = $(current);
            closeMenuTouch($this, 'fa-angle-down');
        });
    });
    $("div.container").on("click", function(e) {
        $('.dropdown').siblings().each(function(index, current) {
            var $this = $(current);
            closeMenuTouch($this, 'fa-angle-down');
        });
    });
}

